import request from "../request"
import Qs from "qs"

// 查询成员统计数据
export function queryReportList() {
    return request({
        method: "post",
        url: `/client/followUp/getAllReport`
    })
}

// 查询成员统计数据
export function queryReport(reportId) {
    return request({
        method: "post",
        url: `/client/followUp/getFollowUpStatusByReportId?reportId=${reportId}`
    })
}

// 查询成员分配规则
export function queryRule(reportId) {
    return request({
        method: "post",
        url: `/client/followUp/treeViewResult`
    })
}
