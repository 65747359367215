<template>
  <div class="container">
    <div class="btn-group">
      <div :class="['btn', { active: active == 1 }]" @click="clickBtn(1)">
        地域视图
      </div>
      <div :class="['btn', { active: active == 2 }]" @click="clickBtn(2)">
        工作室视图
      </div>
    </div>

    <div class="tree-box">
      <el-tree
          :data="active == 1 ? provinceList : studioList"
          icon-class="van-icon van-icon-play"
      />
    </div>
  </div>
</template>

<script>
import {queryRule} from "@/api/feiyu"
import {Tree} from "element-ui"
import "element-ui/lib/theme-chalk/tree.css"

export default {
  components: {[Tree.name]: Tree},
  data() {
    return {
      active: 1,
      studioList: [],
      provinceList: []
    }
  },
  async created() {
    this.$toast.loading()
    const {value} = await queryRule()
    const {provinceList, studioList} = value
    const filterList = (list) => {
      if (!list) return void 0
      return list.map((item) => {
        const {name, employees, provinces, cities} = item
        return {
          label: name,
          children: filterList(employees || provinces || cities)
        }
      })
    }
    this.provinceList = filterList(provinceList)
    this.studioList = filterList(studioList)
    this.$toast.clear()
  },
  methods: {
    clickBtn(e) {
      this.active = e
    }
  }
}
</script>

<style scoped lang="less">
.container {
  padding: 10px;
}

.btn-group {
  .btn {
    font-size: 12px;
    display: inline-block;
    margin-right: 5px;
    padding: 5px 10px;
    border-radius: 5px;

    &.active {
      background-color: var(--mainColor);
      color: #fff;
    }
  }
}

.tree-box {
  margin-top: 10px;
  padding: 12px 8px;
  border-radius: 8px;
  overflow: hidden;
  background-color: var(--cardBackgroundColor);

  /deep/ .el-tree {
    background-color: var(--cardBackgroundColor);
    color: var(--titleColor);

    .el-tree-node__content {
      height: 30px;

      &:hover {
        background-color: var(--cardBackgroundColor);
      }
    }

    .el-tree-node__label {
      font-size: 13px;
    }
  }
}
</style>
